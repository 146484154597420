import classes from './rates-page.module.css';
import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import RateCard from "../components/rates-page/rate-card";
import PdfModal from "../components/rates-page/pdf-modal";
import ValuationTool from '../components/rates-page/valutation-tool';
import Banner from '../components/rates-page/banner';

const RATECARD_OPTIONS = {
  currencies: ["GBP", "USD", "EUR", "CAD", "ZAR", "PLN", "AUD"],
  sectors: ["All Sectors", "Government", "SSA", "Secured", "Secured (Gov & SSA)", "Financial", "Corporate"],
  ratings: ["All Products", "AA- or better", "A- or better"],
}

interface ratesPageProps {
  rates: any;
}

export default function RatesPage({ rates } : ratesPageProps) {
  const appContext = useContext(AppContext);
  const [pdfModalOpen, setPdfModalOpen] = useState<boolean>(false);
  const [valuationOpen, setValuationOpen] = useState<boolean>(true);
  const branding = appContext?.branding;
  const exclude = appContext?.exclude;
  const currencyState = useState<string>("GBP");
  const sectorState = useState<string>("All Sectors");
  const ratingState = useState<string>("All Products");
  const [selectedTerm, setSelectedTerm] = useState<string>("");
  const [selectedRate, setSelectedRate] = useState<number>(0);
  const [isProductSelected, setIsProductSelected] = useState<boolean>(false);
  
  function openValuationTool(term: string, rate: number) {
    setSelectedTerm(term);
    setSelectedRate(rate);
    if (!isProductSelected) { setIsProductSelected(true) };
    if (!valuationOpen) { setValuationOpen(true) };
  };

  useEffect(() => {
    setIsProductSelected(false);
  }, [currencyState[0], sectorState[0], ratingState[0]]);

  const openPdfModal = () => setPdfModalOpen(true);

  const visibleSectors = exclude ? RATECARD_OPTIONS.sectors.filter((sector: string) => {
    return !exclude?.includes(sector);
  }) : RATECARD_OPTIONS.sectors;
  
  RATECARD_OPTIONS.sectors = visibleSectors;

  return <div className={classes.container}>
      <Banner branding={branding} pdfButtonOnClick={openPdfModal} />
      <RateCard 
        data={rates} 
        onTableClick={openValuationTool} 
        onChartClick={openValuationTool}
        {...{currencyState, ratingState, sectorState}}
        {...RATECARD_OPTIONS}
      />
      <PdfModal openState={[pdfModalOpen, setPdfModalOpen]} />
      <ValuationTool
        openState={[valuationOpen, setValuationOpen]}
        currency={currencyState[0]}
        term={selectedTerm}
        rate={selectedRate}
        isProductSelected={isProductSelected}
      />
    </div>
}