import Modal from "../common/modal";
import classes from "./pdf-modal.module.css";
import LinearProgress from '@mui/material/LinearProgress';
import CloseIcon from '@mui/icons-material/Close';
import TextInput from '../common/text-input';
import { useContext, useState } from 'react';
import { isEmailValid, getDateString, downloadBase64Data } from '../../helper-functions';
import { API_URL } from '../../config';
import { AppContext } from '../../App';
import { tsBlueHover } from '../../design/animations';
import { motion } from 'framer-motion';

interface pdfModalProps {
    openState: any;
}

export default function PdfModal({ openState } : pdfModalProps) {

    const appContext = useContext(AppContext);

    const [modalOpen, setModalOpen] = openState;
    const [clientName, setClientName] = useState<string>("");
    const [clientEmail, setClientEmail] = useState<string>("");
    const [requestStatus, setRequestStatus] = useState<"NOT REQUESTED" | "REQUESTED" | "RECEIVED">("NOT REQUESTED");
    const [requestError, setRequestError] = useState<string>("");
    const pricing = appContext?.pricing;
    const rates = appContext?.rates;
    const branding = appContext?.branding;

    const closeModal = () => {
        setModalOpen(false);
        setRequestStatus("NOT REQUESTED");
        setClientName("");
        setClientEmail("");
    }

    async function requestPDF() {
        setRequestError("");
        if (isEmailValid(clientEmail) && clientName !== "") {
            setRequestStatus("REQUESTED");
            const requestData = { clientName, clientEmail, pricing, branding, rates };
            const res = await fetch(API_URL + "/request-pdf", { method: "POST", body: JSON.stringify(requestData) });
            if (res.ok) {
            const jsonData = await res.json();
            await downloadBase64Data('data:application/pdf;base64,' + jsonData['pdf_base64'], `TreasurySpring_Rate_Card_${getDateString("_")}.pdf`);
            setRequestStatus("RECEIVED");
            setClientName("");
            setClientEmail("");
            } else {
            setRequestError("Whoops! Something went wrong, please try again in a moment.")
            }
        } else {
            setRequestError("Please enter a valid name and email address");
        }
    }

    return (
        <Modal open={modalOpen} onClose={closeModal}>
            { requestStatus === "REQUESTED" &&
                <>
                <h2 className={classes.title}>Generating PDF</h2>
                <LinearProgress className={classes.progressBar} />
                </>
            }
            { requestStatus === "RECEIVED" &&
                <>
                <h2 className={classes.title}>PDF Downloaded!<CloseIcon onClick={closeModal} className={classes.closeIcon}/></h2>
                <p>Thanks for downloading a PDF version of our rates. If you would like any further information please don't hesitate to reach out to your relationship manager.</p>
                </>
            }
            { requestStatus === "NOT REQUESTED" &&
                <>
                <h2 className={classes.title}>Download PDF version <CloseIcon onClick={closeModal} className={classes.closeIcon}/></h2>
                <p>To download a PDF version of our rates, please provide us with your name and email address.</p>
                <TextInput state={[clientName, setClientName]} label="Name" className={classes.input} />
                <TextInput state={[clientEmail, setClientEmail]} label="Email Address" className={classes.input} />
                { requestError !== "" && <p className={classes.errorMsg}>{requestError}</p> }
                <motion.button onClick={requestPDF} className={classes.submitButton} {...tsBlueHover}>Download PDF</motion.button>
                </>
            }
        </Modal> 
    );
}