import colours from "./colours";

export function colourOnHover(backgroundColor: string, color?: string, duration?: number) {
    return {
        whileHover: { backgroundColor, color: color || "inherit" },
        transition: { duration: duration || 0.4 }
    }
}

export const tsBlueHover = colourOnHover(colours['ts-blue'], "white");
export const tsGreenHover = colourOnHover(colours['ts-green'], "white");

export const expandAndSquash = {
    initial: {scale: 0},
    animate: {scale: 1},
    exit: {scale: 0}
}