import Dropdown from "../common/dropdown";
import RatesTable from "./rates-table";
import RatesChart from "./rates-chart";
import classes from "./rate-card.module.css";
import { getCurrencyFlag } from "../../helper-functions";

const currencyDropdownStyle = {
  fontWeight: 600,
  fontSize: '1.5rem',
  padding: "15px 12px 4px 12px",
}

interface rateCardProps {
  data: any;
  currencyState: any;
  ratingState: any;
  sectorState: any;
  currencies: string[];
  ratings: string[];
  sectors: string[]
  onTableClick?: (term: string, rate: number) => void;
  onChartClick?: (term: string, rate: number) => void;
}

export default function RateCard({ data, currencyState, ratingState, sectorState, currencies, ratings, sectors, onTableClick, onChartClick } : rateCardProps) {
  const [selectedCurrency, setSelectedCurrency] = currencyState;
  const [selectedRating, setSelectedRating] = ratingState;
  const [selectedSector, setSelectedSector] = sectorState;

  return (
    <div className={classes.container}>
      <h1 className={classes.titleRow}>
        Best Rates
        <Dropdown 
          state={[selectedCurrency, setSelectedCurrency]} 
          options={currencies} 
          displayValues={currencies.map(c => `${getCurrencyFlag(c)} ${c}`)} 
          className={classes.currencyDropdown}
          style={currencyDropdownStyle}
        />
      </h1>
      <div className={classes.dropdowns}>
        <Dropdown state={[selectedSector, setSelectedSector]} options={sectors} className={classes.sectorDropdown} />
        <Dropdown state={[selectedRating, setSelectedRating]} options={ratings} className={classes.ratingDropdown} />
      </div>
      <RatesTable data={data === undefined ? null : data[selectedCurrency][selectedRating][selectedSector]} onClick={onTableClick} />
      <RatesChart data={data === undefined ? null : data[selectedCurrency][selectedRating][selectedSector]} onClick={onChartClick} />
    </div>
  );
}
