const colours = {
    "ts-blue": "#273c4e",
    "ts-green": "#00dc1e",
    "ts-green-light": "#80ED8E",
    "ts-green-lightest": "#D9FADD",
    "ts-ether-green": "#55d690",
    "ts-orange-tertiary": "#F0A045",
    "ts-orange-tertiary-light": "#F7CFA2",
    "ts-blue-tertiary": "#24B0CA",
    "ts-blue-tertiary-light": "#92D7E4",
    "ts-blue-tertiary-25": "#E4F5F8",
    "ts-blue-tertiary-10": "#F4FBFC",
    "ts-red-tertiary": "#FF5C5C",
    "ts-red-tertiary-light": "#FFADAD",

    "lightest-grey": "#fbfbfb"
};

export default colours;