import { motion } from 'framer-motion';
import classes from './banner.module.css';
import logo from '../../assets/logo.svg';
import icdLogo from "../../assets/icd-logo.png";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { tsBlueHover, tsGreenHover } from "../../design/animations";

interface bannerProps {
    branding?: string;
    pdfButtonOnClick?: any;
}

export default function Banner({ branding, pdfButtonOnClick } : bannerProps) {
    return (
    <div className={classes.container}>
        <img src={logo} className={classes.logo} alt="TreasurySpring" />
        <div className={classes.buttons}>
            <motion.button onClick={pdfButtonOnClick} className={classes.pdfButton} {...tsBlueHover}>
                PDF 
                <FileDownloadIcon className={classes.downloadIcon}/>
            </motion.button>
            <motion.a href="https://app.treasuryspring.com" className={classes.loginButton} {...tsBlueHover} target="_blank" rel="noreferrer">
                Platform login
            </motion.a>
            { branding === "icd" ? 
            <>
            <img src={icdLogo} className={classes.icdLogo} alt="ICD" /> 
            <motion.a href="https://treasuryspring.com/contact/icd" className={classes.signUpButton} {...tsGreenHover} target="_blank" rel="noreferrer">
                Sign up
            </motion.a>
            </>
            :
            <motion.a href="https://treasuryspring.com/contact" className={classes.signUpButton} {...tsGreenHover} target="_blank" rel="noreferrer">
                Sign up
            </motion.a> 
            }
        </div>
    </div>
    );
}