import TextField from "@mui/material/TextField";

interface textInputProps {
    state: any;
    label?: string;
    onChange?: any;
    inputProps?: any;
    className?: string;
    style?: any;
    type?: string;
}

export default function TextInput({state, label, onChange, inputProps, className, style, type="text"} : textInputProps) {
    const [value, setValue] = state;
    const defaultOnChange = (ev : any) => setValue(ev.target.value)

    return <TextField
            className={className}  
            label={label} 
            variant="outlined" 
            value={value} 
            onChange={onChange || defaultOnChange} 
            inputProps={inputProps}
            style={style}
            type={type}
          />
}