import logo from '../assets/logo.svg';
import classes from './error-page.module.css';
import { motion } from 'framer-motion';
import { tsBlueHover, tsGreenHover } from '../design/animations';

interface errorPageProps {
    reason?: "error" | "unauthorised"
}

export default function ErrorPage({reason="error"}: errorPageProps) {
    return <>
        <div className={classes.buttonRow}>
            <motion.a href="https://app.treasuryspring.com" className={classes.loginButton} {...tsBlueHover} target="_blank" rel="noreferrer">Platform login</motion.a>
            <motion.a href="https://treasuryspring.com/contact" className={classes.signUpButton} {...tsGreenHover} target="_blank" rel="noreferrer">Sign up</motion.a>
        </div>
        <div className={classes.container}>
            <img src={logo} alt="TreasurySpring" className={classes.logo} />
            { reason === "unauthorised" &&
            <>
                <h1>Sorry, you are not authorised to view this page!</h1>
                <p>If you think this is a mistake, please contact your relationship manager.</p>
            </>
            }
            { reason === "error" && 
            <>
                <h1>Whoops, something went wrong!</h1>
                <p>Please try again in a moment. If this problem persists please get in touch with your relationship manager.</p>
            </>
            }
        </div>
    </>
}