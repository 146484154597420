import { useState, useEffect, createContext, useRef } from 'react';
import classes from "./App.module.css";
import { RatesPage, LoadingPage, ErrorPage } from './views';
import { API_URL } from './config';

interface appContext {
  user: [string];
  pricing: string;
  branding: string;
  rates: any;
  lastRefreshed: string;
  ratesPageRef: any;
  exclude: string[] | undefined;
}
export const AppContext = createContext<appContext | null>(null);

export default function App() {
  const [rates, setRates] = useState<any>(undefined)
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<[string]>([""]);
  const [pricing, setPricing] = useState<string>("");
  const [branding, setBranding] = useState<string>("none");
  const [exclude, setExclude] = useState<string[] | undefined>(undefined);
  const lastRefreshed = new Date().toLocaleString();
  const [error, setError] = useState<"error" | "unauthorised" | undefined>(undefined);
  const ratesPageRef = useRef(null);
  
  useEffect(() => {
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    const tokenData = { token: queryParams.get("token") };
    fetch(API_URL, { method: "POST", body: JSON.stringify(tokenData) }).then(res => {
      if (res.status === 401) { 
        setLoading(false);
        setError("unauthorised");
        return;
      }
      if (res.ok) {
          res.json().then(data => {
          setRates(data.rates_data);
          setUser(data.user);
          setPricing(data.pricing);
          setBranding(data.branding);
          setExclude(data.exclude);
          setLoading(false);
        })
      } else {
        setLoading(false);
        setError("error");
      }
    })
  }, []);

  if (loading) {
    return <LoadingPage />
  }

  if (error) {
    return <ErrorPage reason={error}/>
  }

  return (
    <div className={classes.app}>
      <AppContext.Provider value={{user, pricing, branding, rates, lastRefreshed, ratesPageRef, exclude}}>
        <div className={classes.ratesPage} ref={ratesPageRef}>
          <RatesPage rates={rates} />
        </div>
      </AppContext.Provider>
    </div>
  );
}
