import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import classes from './loading-page.module.css';
import logo from '../assets/logo.svg';

export default function LoadingPage() {
    return <>
        <img src={logo} alt="TreasurySpring" className={classes.logo} />
        <Box className={classes.container}>
            <LinearProgress className={classes.progressBar} />
        </Box>
    </>
}