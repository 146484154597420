const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const CURRENCY_SYMBOLS: any = {
  GBP: "£",
  USD: "$",
  EUR: "€",
  CAD: "$",
  ZAR: "R",
  PLN: "zł",
  AUD: "$",
}

const FLAGS: any = {
  GBP: "🇬🇧",
  USD: "🇺🇸",
  EUR: "🇪🇺",
  CAD: "🇨🇦",
  ZAR: "🇿🇦",
  PLN: "🇵🇱",
  AUD: "🇦🇺",
}

const TERMS: any = {
  "1w": 7,
  "2w": 14,
  "1m": 30,
  "1m ext": 30,
  "2m": 60,
  "2m ext": 60,
  "3m": 90,
  "3m ext": 90,
  "6m": 180,
  "9m": 270,
  "12m": 365,
}

export const isEmailValid = (email : string) => {
    return email.toLowerCase().match(EMAIL_REGEX);
};

export const getDateString = (separator: string = "-") => {
    const d = new Date();
    return d.getDate().toString() + separator + d.getMonth().toString() + separator + d.getFullYear().toString();
}

export async function downloadResponseContent(response: Response, filename: string) {
  const blob = await response.blob()
  const newBlob = new Blob([blob])
  const blobUrl = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
  window.URL.revokeObjectURL(blobUrl);
}

export async function downloadBase64Data(dataURL: string, filename: string) {
  const link = document.createElement('a');
  link.href = dataURL;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
}

export function getCurrencySymbol(currency: string) : string {
  return CURRENCY_SYMBOLS[currency];
}

export function getCurrencyFlag(currency: string) : string {
  return FLAGS[currency];
}

export function termInDays(term: string) : number {
  return TERMS[term];
}

export function maturity(amount: number, term: string, rate: number) : number {
  const value = amount * (1 + (rate/100))**(termInDays(term)/365);
  if (isNaN(value)) { return 0 }
  return value;
}

export function formatMoney(value: number) : string {
  return value.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}
