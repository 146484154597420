import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import classes from "./dropdown.module.css";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import colours from "../../design/colours";

interface dropdownProps {
  label?: string;
  state: any;
  options: Array<string>;
  displayValues?: Array<string>;
  width?: string | number;
  className?: string;
  style?: any;
}

export default function Dropdown({ label, state, options, displayValues, width, className, style }: dropdownProps) {
  const [value, setValue] = state;

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
  };

  const StyledInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      backgroundColor: "white",
      borderRadius: style?.borderRadius || 10,
      border: style?.border || "2px solid white",
      fontSize: style?.fontSize || "1.2rem",
      padding: style?.padding || "12px 12px 8px 12px",
      transition: theme.transitions.create(["border-color"]),
      fontFamily: style?.fontFamily || "Circular, sans-serif",
      fontWeight: style?.fontWeight || 500,
      color: style?.color || colours["ts-blue"],
      marginTop: style?.marginTop || '5px',
      "&:focus": {
        border: `solid 2px ${colours["ts-blue"]}`,
        borderRadius: 10,
      },
      "&:hover": {
        borderColor: colours["ts-blue"],
      }
    },
  }));

  return (
    <Box sx={{ width: width }} className={className}>
      <FormControl fullWidth>
        <InputLabel id="select-label">{label}</InputLabel>
        <Select labelId="select-label" value={value} label={label} onChange={handleChange} input={<StyledInput />} MenuProps={{ classes: { paper: classes.menu }}}>
          {options.map((option: string, key: number) => (
            <MenuItem value={option} key={key}>
              {displayValues ? displayValues[key] : option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
