import classes from "./rates-table.module.css";

interface tableProps {
  data: any;
  onClick?: (term: string, rate: number) => any;
}

export default function RatesTable({ data, onClick }: tableProps) {
  const terms = ["1w", "2w", "1m", "1m ext", "2m", "2m ext", "3m", "3m ext", "6m", "9m", "12m"];
  const dataIsProvided = (data !== null)

  const rates = terms.map((term) => {
    if (dataIsProvided) {
      return data[term] === null ? "-" : data[term].toFixed(2);
    }
    return "-";
  });

  function handleClick(term: string, rate: string) {
    const rateNum = parseFloat(rate);
    if (!isNaN(rateNum)) {
      if (onClick) { onClick(term, rateNum) };
    }
  }

  return (
    <table className={classes.table}>
      <tbody>
        <tr>
          {terms.map((term, idx) => (
            <th key={idx}>
              {term}
            </th>
          ))}
        </tr>
        <tr>
          {rates.map((rate, idx) => (
            <td key={idx} onClick={() => handleClick(terms[idx], rate)}>
              {rate}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
}
