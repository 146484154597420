import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, TooltipItem, Legend, ChartData, ChartOptions } from "chart.js";
import { Bar } from "react-chartjs-2";
import classes from "./rates-chart.module.css";
import colours from "../../design/colours";
import { useRef, useContext } from "react";
import { AppContext } from "../../App";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
interface chartProps {
  data: any;
  hasKey?: boolean;
  onClick?: (term: string, rate: number) => void;
}

const TERMS = ["1w", "2w", "1m", "1m ext", "2m", "2m ext", "3m", "3m ext", "6m", "9m", "12m"];

export default function RatesChart({ data, onClick }: chartProps) {
  const appContext = useContext(AppContext);
  const dataIsProvided = (data !== null)
  const chartRef = useRef<any>(null);
  const lastRefreshed = appContext?.lastRefreshed;

  const rates = TERMS.map((term) => {
    if (dataIsProvided) {
      return data[term] || 0;
    }
    return 0;
  });

  const handleClick = (event : any) => {
    const chart = chartRef.current;
    const points = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
    if (points.length) {
      const firstPoint = points[0];
      var label = chart.data.labels[firstPoint.index];
      var value = chart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
      if (onClick) { onClick(label, value) };
    }
  }

  const options: ChartOptions<"bar"> = {
    responsive: true,
    onClick: handleClick,
    onHover: (event: any, chartElement) => {
      event.native.target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
    scales: {
      x: {
        grid: { display: false, drawBorder: false },
        ticks: {
          color: colours["ts-blue"],
          font: {
            family: "Circular, sans-serif",
            size: 14,
          },
        },
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "white",
        titleFont: {
          family: "Circular, sans-serif",
          size: 18,
          weight: "500",
        },
        titleColor: '#273c4e',
        padding: 7,
        bodyFont: {
          family: "Circular, sans-serif",
          size: 16,
          weight: "500",
        },
        bodyColor: '#273c4e',
        borderColor: 'whitesmoke',
        borderWidth: 2,
        boxPadding: 4,
        callbacks: {
          title: function(context: TooltipItem<"bar">[]) {
            return context[0].label;
          },
          label: function(context: TooltipItem<"bar">) {
              const raw = context.raw as number;
              return `${raw.toFixed(2)}%`;
          }
        }
      }
    },
  };

  const chartData: ChartData<"bar"> = {
    labels: TERMS,
    datasets: [
      {
        data: rates,
        backgroundColor: colours["ts-ether-green"],
        borderRadius: 10,
        borderSkipped: false,
      },
    ],
  };

  return <>
    <Bar ref={chartRef} options={options} data={chartData} className={classes.chart} />
    <div className={classes.chartFooter}>
      <span className={classes.updatedTime}>Last refreshed <strong>{lastRefreshed || "-"}</strong></span>
      <a href="https://treasuryspring.com/wp-content/uploads/2024/01/Master_Back-Cover_2023.12.04.pdf" className={classes.disclaimer} target="_blank" rel="noreferrer">Full disclaimer</a>
    </div>
    <p className={classes.shortDisclaimer}>
      In return for our agreeing to provide this information to you, you acknowledge and agree that it is provided for your information purposes only, is not to be shared, and does not constitute legal, investment or other advice provided to you by TreasurySpring Management (Jersey) Limited, TreasurySpring Limited or TreasurySpring Advisors Limited (together with each of our affiliates, “we”, “us”, or “our”) or any of our advisers. Whilst we endeavour to ensure this information is accurate and up-to-date as at the time it is generated, the information shown is based on several factors which are constantly changing, for example factors related to your business needs and subscription requirements. As such, all information is liable to change without notice and should therefore be read as indicative only. Any reliance you place on this information is strictly at your own risk.
    </p>
  </>
}
