import MuiModal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import classes from "./modal.module.css";
import React from 'react';

interface modalProps {
    children: React.ReactNode;
    open: boolean;
    onClose: (args: any[]) => any;
    className?: string;
}

export default function Modal({ children, open, onClose, className } : modalProps) {
    return (
    <MuiModal open={open} onClose={onClose} className={className}>
        <Box className={classes.modal}>
            { children }
        </Box>
    </MuiModal>
    );
}