import { motion, AnimatePresence } from 'framer-motion';
import classes from './valuation-tool.module.css';
import TextInput from '../common/text-input';
import { useState } from 'react';
import CalculateIcon from '@mui/icons-material/Calculate';
import CloseIcon from '@mui/icons-material/Close';
import { getCurrencySymbol, maturity, termInDays, formatMoney } from '../../helper-functions';
import { expandAndSquash } from '../../design/animations';
import { toWords } from 'number-to-words';

interface valuationToolProps {
    openState: any;
    currency: string;
    term: string;
    rate: number;
    isProductSelected: boolean;
}

export default function ValuationTool( { openState, currency, term, rate, isProductSelected } : valuationToolProps ) {
    const [ amount, setAmount ] = useState<number | undefined>(undefined);
    const [ isOpen, setIsOpen ] = openState;
    const [ selectedCalcOption, setSelectedCalcOption ] = useState<"Maturity income" | "Maturity value" | "Annualized income">("Maturity value");

    const closeTool = () => setIsOpen(false);
    const toggleTool = () => setIsOpen(!isOpen);
    const handleCalcOptionChanged = (event : any) : void => {
        event.preventDefault();
        setSelectedCalcOption(event.target.innerText);
    }

    const calcValue = () => {
        if (amount && isProductSelected) {
            switch (selectedCalcOption) {
                case "Maturity income":
                    return formatMoney(maturity(amount, term, rate) - amount);
                case "Annualized income": 
                    return formatMoney(maturity(amount, "12m", rate) - amount);
                default: // maturity value
                    return formatMoney(maturity(amount, term, rate));
            }
        }
        return "0.00";
    }

    const toolContent = <>
        <span className={classes.title}>
            { isProductSelected ? 
                <h4>{currency} {term} at {rate.toFixed(2)}%</h4> 
            : 
                <h4 className={classes.tsRed}>Click on a product from the chart or table to see how much you can earn</h4> 
            }
            <CloseIcon onClick={closeTool} className={classes.closeIcon}/>
        </span>
        <TextInput state={[amount, setAmount]} label="Amount to invest" className={classes.input} type="number" />
        { amount && <p className={classes.amountInWords}>{toWords(amount)}</p> }
        <div className={classes.calcOptions}>
            <div className={selectedCalcOption === 'Maturity value' ? classes.selected : ""} onClick={handleCalcOptionChanged}>
                Maturity value
            </div>
            <div className={selectedCalcOption === 'Maturity income' ? classes.selected : ""} onClick={handleCalcOptionChanged}>
                Maturity income
            </div>
            <div className={selectedCalcOption === 'Annualized income' ? classes.selected : ""} onClick={handleCalcOptionChanged}>
                Annualized income
            </div>
        </div>
        <h1>
            <span className={classes.tsGreen}>{getCurrencySymbol(currency)}</span>
            {calcValue()}
        </h1>
        { isProductSelected && <p className={classes.disclaimer}>Note: calculations are based on the assumption that {term.split(" ")[0]} = {termInDays(term)} days. The exact term of some products may vary.</p> }
    </>

    return (
        <AnimatePresence>
            { isOpen ? 
            <>
                <motion.div onClick={toggleTool} className={classes.calcButton} {...expandAndSquash} key="close-valuation-tool">
                    <CloseIcon className={classes.calcCloseIcon} /> 
                </motion.div>
                <motion.div className={classes.container} {...expandAndSquash} key="valuation-tool-content">
                    {toolContent}
                </motion.div> 
            </> 
            : 
            <motion.div onClick={toggleTool} className={classes.calcButton} {...expandAndSquash} key="open-valuation-tool">
                <CalculateIcon className={classes.calcIcon} />  
            </motion.div>
            }
        </AnimatePresence>
    );
}